<script>
import { mapState } from 'vuex'

import ge2gjnlService from '@/services/entry/ge2gjnl.js'
import { toastMixins, datetimeMixins } from '@/mixins'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [datetimeMixins, toastMixins],
  components: { MenuBar },
  data() {
    return {
      templateSelected: null,
      sortBySelected: 'templateCode',
      sortByOptions: [
        { value: 'templateCode', text: 'รหัส' },
        { value: 'journalCode', text: 'สมุดรายวัน' },
        { value: 'templateName', text: 'รายละเอียด' },
        { value: 'userIdGroupCode', text: 'กลุ่มผู้ใช้งาน' },
        { value: 'userId', text: 'สร้างโดย' },
        { value: 'updatedAt', text: 'ปรับปรุงเมื่อ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      searchBySelected: 'templateCode',
      searchByOptions: [
        { value: 'templateCode', text: 'รหัส' },
        { value: 'journalCode', text: 'สมุดรายวัน' },
        { value: 'templateName', text: 'รายละเอียด' },
        { value: 'userIdGroupCode', text: 'กลุ่มผู้ใช้งาน' },
        { value: 'userId', text: 'สร้างโดย' }
        // { value: 'updatedAt', text: 'ปรับปรุงเมื่อ' }
      ],
      searchKeyword: '',
      searchKeywordSetTimer: null,
      templates: [],
      tableFields: [
        { field: 'templateCode', title: 'รหัส', align: 'center' },
        {
          field: 'journalCode',
          title: 'สมุดรายวัน',
          align: 'center'
        },
        {
          field: 'templateName',
          title: 'รายละเอียด',
          align: 'center'
        },
        {
          field: 'userIdGroupCode',
          title: 'ผู้ใช้งาน/กลุ่มผู้ใช้งาน',
          align: 'center'
        },
        {
          field: 'lastUpdatedBy',
          title: 'สร้างโดย',
          align: 'center'
        },
        {
          field: 'updatedAtTH',
          title: 'ปรับปรุงเมื่อ',
          align: 'center'
        }
      ],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultKeywordTimerMs: state => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    sortBySelected() {
      this.fetchJournalDocumentTemplates()
    },
    sortTypeSelected() {
      this.fetchJournalDocumentTemplates()
    },
    searchBySelected() {
      this.fetchJournalDocumentTemplates()
    },
    searchKeyword() {
      if (this.searchKeywordSetTimer) {
        clearTimeout(this.searchKeywordSetTimer)
      }
      this.searchKeywordSetTimer = setTimeout(() => {
        this.fetchJournalDocumentTemplates()
      }, this.defaultKeywordTimerMs)
    }
  },
  async created() {
    await this.initialize()
  },
  methods: {
    async initialize() {
      await this.fetchJournalDocumentTemplates()
    },
    async fetchJournalDocumentTemplates() {
      try {
        const qsArray = []
        if (this.sortBySelected) qsArray.push(`sortBy=${this.sortBySelected}`)
        if (this.sortTypeSelected)
          qsArray.push(`sortType=${this.sortTypeSelected}`)
        if (this.searchBySelected)
          qsArray.push(`searchBy=${this.searchBySelected}`)
        if (this.searchKeyword && this.searchKeyword.trim().length > 0)
          qsArray.push(`keyword=${this.searchKeyword.trim()}`)
        const qs = qsArray.join('&')

        const res = await ge2gjnlService.getJournalDocumentTemplates({ qs })
        this.templates = [...this.formatTemplates(res.data.data)]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงรายการต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    formatTemplates(templates) {
      return templates.map(template => ({
        ...template,
        updatedAtTH: this.mxConvertToDatetimeBuddhistFormat(template.updatedAt)
      }))
    },
    getSearchKeywordPlaceholder(searchBySelected) {
      const selectedOption = this.searchByOptions.find(
        option => option.value === searchBySelected
      )
      return selectedOption ? selectedOption.text : ''
    },
    rowClickedHandler(selectedTemplate) {
      this.templateSelected = selectedTemplate
    },
    rowDoubleClickedHandler(template) {
      this.$router.push(
        `/entry/ge2gjnl/journal-document-templates/detail/${template.id}`
      )
    },
    getRowCss(row) {
      if (this.templateSelected) {
        if (this.templateSelected.id === row.id) {
          return { backgroundColor: 'lightblue' }
        }
      }
      return null
    },
    useStyles(key) {
      switch (key) {
        case 'datagrid-style':
          return { height: '500px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <span class="text-xxl text-white" style="text-shadow: 2px 2px grey;">
          ต้นแบบป้อนรายการ
        </span>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :templateSelectedProp="templateSelected"
          @updateTable="fetchJournalDocumentTemplates"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- ส่วนของ selection & search -->
    <b-row class="my-3 d-flex justify-content-center">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-by-selection">
              เรียงตาม
            </label>
            <b-form-select
              id="sort-by-selection"
              v-model="sortBySelected"
              :options="sortByOptions"
              style="font-size: 0.9rem;"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-type-selection">
              เรียงแบบ
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="sortTypeSelected"
              :options="sortTypeOptions"
              style="font-size: 0.9rem;"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-type-selection">
              ค้นหาโดย
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="searchBySelected"
              :options="searchByOptions"
              style="font-size: 0.9rem;"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="search-input">
              ค้นหา
            </label>
            <b-form-input
              id="search-input"
              v-model="searchKeyword"
              :placeholder="getSearchKeywordPlaceholder(this.searchBySelected)"
              style="font-size: 0.9rem;"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ส่วนของ table -->
    <b-row class="d-flex flex-column align-items-center">
      <b-col cols="12" class="text-center">
        <div class="bg-light py-2">
          <h3>ต้นแบบป้อนรายการ</h3>
        </div>
      </b-col>
      <b-col cols="12" class="text-center">
        <DataGrid
          :data="templates"
          :rowCss="getRowCss"
          :style="useStyles('datagrid-style')"
          @rowClick="rowClickedHandler"
          @rowDblClick="rowDoubleClickedHandler"
        >
          <GridColumn
            v-for="field in tableFields"
            :key="field.field"
            :field="field.field"
            :title="field.title"
            :align="field.align"
          ></GridColumn>
        </DataGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
