<template>
  <div>
    <div>
      <span>กรุณาเลือกต้นแบบป้อนรายการที่ต้องการจะแก้ไข</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
